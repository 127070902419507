import { render, staticRenderFns } from "./sidentify.vue?vue&type=template&id=47c8412e&scoped=true"
import script from "./sidentify.vue?vue&type=script&lang=js"
export * from "./sidentify.vue?vue&type=script&lang=js"
import style0 from "./sidentify.vue?vue&type=style&index=0&id=47c8412e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c8412e",
  null
  
)

export default component.exports