<template>
  <div class="regBgImg">
    <div class="regCover">
      <div class="regTitleFlex">
        <div>
          <h1>产教融合 培育电商人才</h1>
          <p>Industry and education merge to nurture e-commerce talent</p>
        </div>
        <div>
          <div class="regPhoneFlex">
            <img src="images/phone.png" alt="" />
            <span>0531-88259780</span>
          </div>
          <p>全 国 免 费 咨 询 电 话</p>
        </div>
      </div>
      <div class="regcontent">
        <div class="regcontentPad">
          <div class="logoBorder">
            <img src="images/eLogoSvg.png" alt="" />
<!--            <p >育工匠 融校企 促就业 创未来</p>-->
          </div>
          <!-- 注册 -->
          <div v-if="regShow">
            <el-form ref="form" :model="form" label-width="120px">
              <el-form-item>
                <p class="regTitle">注册</p>
              </el-form-item>
              <el-form-item label="*注册手机号码:">
                <el-input
                  v-model="form.phone"
                  placeholder="请填写手机号码"
                ></el-input>
              </el-form-item>
              <el-form-item label="*短信验证码：">
                <div class="infoCodeFlex">
                  <el-input
                    v-model="form.code"
                    placeholder="请填写验证码"
                  ></el-input>
                  <p v-if="regtimeshow" class="getCode" @click="getCode()">获取验证码</p>
                  <p v-if="!regtimeshow" class="getCode" >{{ count }}s后重新获取</p>
                </div>
              </el-form-item>
              <el-form-item label="*密码:" prop="password">
                <el-input
                  v-model="form.password"
                  placeholder="设置6~16位密码"
                  type="password"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-checkbox v-model="agreementChecked" disabled>
                  <span>表示已同意</span>
                </el-checkbox>
                <span @click="goPrivacy" style="cursor: pointer;">《隐私政策》</span>
                <span @click="goRegAgreement" style="cursor: pointer;">《用户协议》</span>
<!--                <iframe src="http://exiaozhi.sdzjds.com/privacy/privacy.html">《隐私政策》</iframe>-->
<!--                <iframe src="http://exiaozhi.sdzjds.com/privacy/safe.html">《用户协议》</iframe>-->
              </el-form-item>
              <el-form-item>
                <p class="querenReg" @click="userReg">确认注册</p>
              </el-form-item>
              <el-form-item>
                <p class="goLoginCenter">
                  <span>已有账号,</span
                  ><span class="goLoginQ" @click="goLoginshow">前往登录>></span>
                </p>
              </el-form-item>
            </el-form>
          </div>
          <!-- 登录 -->
          <div v-if="loginReveal">
            <div class="jobLogin" @click="goJobLogin">job登录</div>
            <el-form ref="Loginform" :model="Loginform" label-width="70px">
              <template v-if="!seekPasswordShow&&!JobloginAccountShow">
                <el-form-item>
                  <div class="loginFlex">
                    <span
                      @click="changeLogin(index)"
                      v-for="(item, index) in loginArr"
                      :key="index"
                      :class="loginshow == index ? 'loginActive' : ''"
                      >{{ item.name }}</span
                    >
                  </div>
                </el-form-item>
              </template>
              <template v-if="!seekPasswordShow&&JobloginAccountShow">
                <el-form-item>
                  <div class="loginFlex">
                    <span
                        @click="changeJobLogin(index)"
                        v-for="(item, index) in jobLoginArr"
                        :key="index"
                        :class="loginshow == index ? 'loginActive' : ''"
                    >{{ item.name }}</span
                    >
                  </div>
                </el-form-item>
              </template>
<!--              小智Job登录-->
              <template v-if="JobloginAccountShow">
                <el-form-item>
                  <el-input
                      v-model="Loginform.stloginPhone"
                      placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="loginPassword">
                  <el-input
                      size="small"
                      placeholder="请输入密码"
                      type="password"
                      v-model="Loginform.stloginPassword"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <el-input
                        size="small"
                        placeholder="验证码"
                        v-model="Loginform.stloginCode"
                    >
                    </el-input>
                    <div class="code" @click="refreshCode">
                      <SIdentify :identifyCode="identifyCode"></SIdentify>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <div>
                      <span>还未注册?</span>
                      <span class="changtereg" @click="changeReg">点击注册</span>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <p class="querenReg" @click="phoneLogin()">登录Job</p>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <p class="infoCup" @click="changeNoteLogin">切换短信登录</p>
                    <div class="spartyCup">
                      <div  @click="loginQQ()">
                        <img src="images/QQ.png" alt="" />
                      </div>
                      <div @click="loginWx()">
                        <img  src="images/wx.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </template>
              <!-- 教师登录 -->
              <template v-if="teacherShow">
                <el-form-item>
                  <el-input
                    v-model="Loginform.loginPhone"
                    placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="loginPassword">
                  <el-input
                    size="small"
                    placeholder="请输入密码"
                    type="password"
                    v-model="Loginform.loginPassword"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <el-input
                      size="small"
                      placeholder="验证码"
                      v-model="Loginform.loginCode"
                    >
                    </el-input>
                    <div class="code" @click="refreshCode">
                      <SIdentify :identifyCode="identifyCode"></SIdentify>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <el-checkbox v-model="Loginform.loginchecked"
                      >记住密码</el-checkbox
                    >
                  </div>
                </el-form-item>
                <el-form-item>
                  <p class="querenReg" @click="teacherLogin">登录</p>
                </el-form-item>
                <el-form-item>
                  <p style="text-align: end">
                    <img src="images/wx.png" alt="" style="cursor: pointer" @click="teacherLoginWx()"/>
                  </p>
                </el-form-item>
              </template>
              <!-- 学生登录 -->
              <template v-if="loginAccountShow">
                <el-form-item>
                  <el-input
                    v-model="Loginform.stloginPhone"
                    placeholder="请输入账号"
                  ></el-input>
                </el-form-item>
                <el-form-item prop="loginPassword">
                  <el-input
                    size="small"
                    placeholder="请输入密码"
                    type="password"
                    v-model="Loginform.stloginPassword"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <el-input
                      size="small"
                      placeholder="验证码"
                      v-model="Loginform.stloginCode"
                    >
                    </el-input>
                    <div class="code" @click="refreshCode">
                      <SIdentify :identifyCode="identifyCode"></SIdentify>
                    </div>
                  </div>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <div>
                      <span>还未注册?</span>
                      <span class="changtereg" @click="changeReg">点击注册</span>
                    </div>
                    <p class="changteregPassword" @click="changeSeekPassword">忘记密码?</p>
                  </div>
                </el-form-item>
                <el-form-item>
                  <p class="querenReg" @click="phoneLogin()">登录</p>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <p class="infoCup" @click="changeNoteLogin">切换短信登录</p>
<!--                    学生第三方登录-->
                    <div class="spartyCup">
                      <div  @click="loginQQ()">
                        <img src="images/QQ.png" alt="" />
                      </div>
                      <div @click="loginWx()">
                        <img  src="images/wx.png" alt="" />
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </template>
              <!-- 短信登录 -->
              <template v-if="noteLoginShow">
                <el-form-item>
                  <el-input
                    v-model="Loginform.noteloginPhone"
                    placeholder="请输入手机号"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <el-input
                      size="small"
                      placeholder="验证码"
                      v-model="Loginform.noteloginCode"
                    >
                    </el-input>
                    <p v-if="logintimeshow" class="getLoginCode" @click="getLoginCode()">获取验证码</p>
                    <p v-if="!logintimeshow" class="getLoginCode" >{{ loginCount }}s后重新获取</p>
                  </div>
                </el-form-item>
                <el-form-item>
                  <p class="querenReg" @click="noteInfoLogin">登录</p>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <p class="infoCup" @click="changeAccountLogin">
                      切换账号登录
                    </p>
                    <div class="spartyCup">
                      <img src="images/QQ.png" alt="" />
                      <img src="images/wx.png" alt="" />
                    </div>
                  </div>
                </el-form-item>
              </template>
              <!-- 找回密码 -->
              <template v-if="seekPasswordShow">
                <el-form-item>
                  <p class="seekPassword">忘记密码</p>
                </el-form-item>
                <el-form-item>
                  <el-input
                      autocomplete="off"
                    v-model="Loginform.seekloginPhone"
                    placeholder="请输入手机号"
                      type="text"
                  ></el-input>
                </el-form-item>
                <el-form-item>

                  <div class="infoCodeFlex">
                    <el-input
                        autocomplete="off"
                      v-model="Loginform.seekLogincode"
                      placeholder="请填写验证码"
                    ></el-input>
                    <p v-if="regtimeshow" class="getCode" @click="getUpPasswordCode()">获取验证码</p>
                    <p v-if="!regtimeshow" class="getCode" >{{ count }}s后重新获取</p>
                  </div>
                  <div class="hidden-input">
                    <!--让浏览器自动填充到这个input-->
                    <input type="text" class="form-control">
                  </div>
                </el-form-item>
                <el-form-item prop="loginPassword" class="regInputColor">
                  <div class="hidden-input">
                    <!--让浏览器自动填充到这个input-->
                    <input type="password" class="form-control">
                  </div>
                  <input
                      class="regInputPassword"
                      autocomplete="off"
                    size="small"
                    placeholder="请输入新密码"
                    type="password"
                    v-model="Loginform.seekLoginPassword"
                  >
                  </input>
                </el-form-item>
                <el-form-item>
                  <p class="querenReg" @click="findPassword">确定</p>
                </el-form-item>
                <el-form-item>
                  <div class="loginCodeFlex">
                    <p class="infoCup" @click="changeAccountLogin">
                      切换账号登录
                    </p>
<!--                    <div class="spartyCup">-->
<!--                      <div>-->
<!--                        <img src="images/QQ.png" alt="" />-->
<!--                      </div>-->
<!--                      <div @click="loginWx()">-->
<!--                        <img  src="images/wx.png" alt="" />-->
<!--                      </div>-->
<!--                    </div>-->
                  </div>
                </el-form-item>
              </template>
            </el-form>
          </div>
        </div>
      </div>
      <div class="regadress">
        <p>总部地址：济南市历城区荣盛时代广场</p>
        <p>
          <span>邮编：250000</span>
          <span>全国免费咨询电话：0531-88259780</span>
        </p>
      </div>
    </div>
    <el-dialog :visible.sync="wxLogin.showWxReg" width="400px" center :show-close="false">
      <wxlogin :appid="wxLogin.appid" :scope="wxLogin.scope" :state="wxLogin.state" :self_redirect="false" :redirect_uri="wxLogin.redirect_uri"></wxlogin>
    </el-dialog>
  </div>
</template>
<script type="text/javascript"  charset="utf-8"
        src="http://connect.qq.com/qc_jssdk.js"
        data-appid="101968322"
        data-redirecturi="http%3A%2F%2Fexiaozhi.sdzjds.com%2F"
></script>
<script>
import wxlogin from "@/components/vue-wxlogin";
import SIdentify  from "../components/sidentify.vue";
import { updateStudyDay,teacherLoginUrl,studentUserReg,sendPhoneCode,studentCodeLogin,studentPasswordLogin,studentUpPassword,queryAndCacheUserCollectionCourseList} from "@/api";
export default {
  components: {
    SIdentify: SIdentify,
    wxlogin
  },
  data() {
    return {
      JobloginAccountShow:false,
      wxLogin: {
        showWxReg: false,
        appid:'wx448c5bc5dbbc7c51',
        redirect_uri: 'http%3A%2F%2Fexiaozhi.sdzjds.com%2F',
        scope:'snsapi_login',
        state:"wxLogin"
      },
      form: {
        phone: "",
        code: "",
        password: "",
        checked: false,
      },
      Loginform: {
        loginPassword: "",
        loginPhone: "",
        loginCode: "",
        loginchecked: false,
        stloginPassword: "",
        stloginPhone: "",
        stloginCode: "",
        stloginchecked: false,
        noteloginPhone: "",
        noteloginCode: "",
        seekLoginPassword:'',
        seekloginPhone: "",
        seekLogincode: "",
      },
      regShow: false,
      loginArr: [
        { id: 1, name: "教师登录" },
        { id: 2, name: "学生登录" },
      ],
      jobLoginArr:[
        {id:1,name:'个人登录'},
        {id:2,name:'企业登录'}
      ],
      loginshow: 0,
      teacherShow: true,
      loginAccountShow: false,
      noteLoginShow: false,
      seekPasswordShow:false,
      loginReveal:true,
      identifyCodes: "1234567890",
      identifyCode: "",
      count: "",
      loginCount:'',
      regtimeshow: true,
      logintimeshow:true,
      agreementChecked:true
    };
  },
  methods: {
    //去小智job登录
    goJobLogin(){
      this.$router.push('/xzjobLogin')
    },
    //微信登录
    loginWx(){
      this.wxLogin.showWxReg = true;
      sessionStorage.setItem('userWxlogin','userWxlogin')
    },
    //教师微信登录
    teacherLoginWx(){
      this.wxLogin.showWxReg = true;
      sessionStorage.setItem('teacherWxlogin','teacherWxlogin')
    },
    //qq登录
    loginQQ(){
      let url = this.$util.getQQLoginUrl({});
      window.location.href = url
    },
    // 教师或学生登录
    changeLogin(index) {
      this.loginshow = index;
      if (index == 0) {
        this.teacherShow = true;
        this.loginAccountShow = false;
        this.noteLoginShow = false;
        this.seekPasswordShow=false
      } else if (index == 1) {
        this.teacherShow = false;
        this.loginAccountShow = true;
        this.noteLoginShow = false;
        this.seekPasswordShow=false
      }
    },
    //job登录
    changeJobLogin(index){
      this.loginshow = index;
      this.JobloginAccountShow=true;
      this.teacherShow = false;
      this.noteLoginShow=false
    },
    // 短信登录
    changeNoteLogin() {
      this.teacherShow = false;
      this.loginAccountShow = false;
      this.noteLoginShow = true;
      this.seekPasswordShow=false
      this.JobloginAccountShow=false
    },
    // 账号登录显示
    changeAccountLogin() {
      this.teacherShow = false;
      this.loginAccountShow = true;
      this.noteLoginShow = false;
      this.seekPasswordShow=false
    },
    // 找回密码
    changeSeekPassword(){
      this.seekPasswordShow=true;
      this.teacherShow = false;
      this.loginAccountShow = false;
      this.noteLoginShow = false;
    },
    // 显示注册
    changeReg(){
      this.loginReveal=false;
      this.regShow = true;
    },
    // 去登录
    goLoginshow(){
      if(this.$route.query.xzjob){
        this.JobloginAccountShow=true;
        this.loginReveal=true;
        this.teacherShow = false;
        this.loginAccountShow = false;
        this.noteLoginShow = false;
        this.seekPasswordShow=false
        this.regShow = false;
      }else{
        this.loginReveal=true;
        this.teacherShow = false;
        this.loginAccountShow = true;
        this.noteLoginShow = false;
        this.seekPasswordShow=false
        this.regShow = false;
        this.JobloginAccountShow=false;
      }

    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
            this.randomNum(0, this.identifyCodes.length)
            ];
      }
    },
  //  教师登录
    teacherLogin(){
      if (!this.Loginform.loginPhone) {
        this.$message.error("账号不可为空");
        return;
      }
      if(this.Loginform.loginPassword==''){
        this.$message.error("密码不可为空");
        return;
      }
      if(this.Loginform.loginCode!==this.identifyCode){
        this.$message.error("验证码输入不正确");
        return;
      }
      let data={
        account: this.Loginform.loginPhone,
        password: this.Loginform.loginPassword,
        terminalType:'WEB'
      }
      teacherLoginUrl(data).then(res => {
        let userInfo = JSON.stringify(res.data)
        localStorage.setItem("userInfo", userInfo);
        if (res.code == 0)  {
          this.$message({
            message: "登录成功",
            type: "success",
            duration:2000
          });
          if (this.Loginform.loginchecked) {
            if(res.data.name){
              this.$cookie.set("userName", res.data.name, {
                expires: 7,
              });
            }else{
              this.$cookie.set("userName", res.data.account, {
                expires: 7,
              });
            }
          } else {
            if(res.data.name){
              this.$cookie.set("userName", res.data.name);
            }else{
              this.$cookie.set("userName", res.data.account);
            }
          };
          this.$cookie.set("userToken", res.data.loginToken);
          this.$store.commit("telNumber", res.data.telNumber);
          if(res.data.name){
            this.$store.commit("setUserName", res.data.name);
          }else{
            this.$store.commit("setUserName", res.data.account);
          }
          if(this.$route.query && String(this.$route.query.r) === 'true'){
            this.$router.push({ path: "/classCenter" });
            this.$other.$emit("indexHeaderShow", 1);
          }else if(this.$route.query && String(this.$route.query.actual) === 'true'){
            this.$router.push({ path: "/raiseBody" });
            this.$other.$emit("indexHeaderShow", 2);
          }else if(this.$route.query && String(this.$route.query.college) === 'true'){
            this.$router.push({ path: "/vocationalExeam" });
            this.$other.$emit("indexHeaderShow", 3);
          }else{
            this.$router.push("/");
            location.reload()
          }
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  学生登录时获取验证码
    getLoginCode(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.Loginform.noteloginPhone)) {
        this.$message.error("手机格式不对");
        return;
      }
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.loginCount = TIME_COUNT;
        this.logintimeshow = false;
        this.timer = setInterval(() => {
          if (this.loginCount > 0 && this.loginCount <= TIME_COUNT) {
            this.loginCount--;
          } else {
            this.logintimeshow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      };
      let data={
        phone:this.Loginform.noteloginPhone
      };
      sendPhoneCode(data).then(res=>{
        if(res.code!==0){
          this.$message.error(res.msg)
        }
      })
    },
  //  学生注册时获取验证码
    getCode(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.form.phone)) {
        this.$message.error("手机格式不对");
        return;
      }
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.regtimeshow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.regtimeshow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      };
      let data={
        phone:this.form.phone
      };
      sendPhoneCode(data).then(res=>{
        if(res.code!==0){
          this.$message.error(res.msg)
        }
      })
    },
  //  学生注册
    userReg(){
      if (!this.form.code) {
        this.$message.error("验证码不可为空");
        return;
      }
      if (!/^[a-zA-Z0-9]{6,16}$/.test(this.form.password)) {
        this.$message.error("密码格式不对");
        return;
      }
      let data1={
        terminalType:'WEB',
        telNumber:this.form.phone,
        password:this.form.password,
        verifyCode:this.form.code
      };
      studentUserReg(data1).then(res=>{
        if (res.code == 0) {
          this.$message({
            message: "注册成功",
            type: "success",
          });
          let query1={
            telNumber:this.form.phone,
            password:this.form.password,
            terminalType:'WEB'
          };
          studentPasswordLogin(query1).then(res=>{
            if(res.code==0){
              this.$message({
                message: "登录成功",
                type: "success",
              });
              let userInfo = JSON.stringify(res.data)
              localStorage.setItem("userInfo", userInfo);
              this.$cookie.set("userToken", res.data.loginToken);
              this.$store.commit("telNumber", res.data.telNumber);
              this.$store.commit("setUserName", res.data.name);
              this.$router.push("/");
              //缓存用户收藏课程id列表
              queryAndCacheUserCollectionCourseList();
            }else{
              this.$message.error(res.msg)
            }
          })
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  短信登录
    noteInfoLogin(){
      if (!this.Loginform.noteloginCode) {
        this.$message.error("验证码不可为空");
        return;
      }
      let query={
        telNumber:this.Loginform.noteloginPhone,
        verifyCode:this.Loginform.noteloginCode,
        terminalType:'WEB'
      };
      studentCodeLogin(query).then(res=>{
        if(res.code==0){
          this.$message({
            message: "登录成功",
            type: "success",
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          this.$store.commit("telNumber", res.data.telNumber);
          this.$store.commit("setUserName", res.data.name);
          this.$router.push("/");
          location. reload()
          this.getUpdateStudyDay()
          //缓存用户收藏课程id列表
          queryAndCacheUserCollectionCourseList();
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  学生手机号密码登录
    phoneLogin(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.Loginform.stloginPhone)) {
        this.$message.error("手机格式不对");
        return;
      }
      if(this.Loginform.stloginPassword==''){
        this.$message.error("密码不可为空");
        return;
      }
      if(this.Loginform.stloginCode!==this.identifyCode){
        this.$message.error("验证码输入不正确");
        return;
      }
      let data={
        telNumber:this.Loginform.stloginPhone,
        password:this.Loginform.stloginPassword,
        terminalType:'WEB'
      };
      studentPasswordLogin(data).then(res=>{
        if(res.code==0){
          this.$message({
            message: "登录成功",
            type: "success",
          });
          let userInfo = JSON.stringify(res.data)
          localStorage.setItem("userInfo", userInfo);
          this.$cookie.set("userToken", res.data.loginToken);
          if(res.data.name){
            this.$cookie.set("userName", res.data.name);
            this.$store.commit("setUserName", res.data.name);
          }
          this.$store.commit("telNumber", res.data.telNumber);
          this.getUpdateStudyDay()
          //缓存用户收藏课程id列表
          queryAndCacheUserCollectionCourseList();
          if(this.$route.query && String(this.$route.query.r) === 'true'){
            this.$router.push({ path: "/userCenterIndex" });
            this.$other.$emit("indexHeaderShow", 1);
          }else if(this.$route.query && String(this.$route.query.actual) === 'true'){
            this.$router.push({ path: "/raiseBody" });
            this.$other.$emit("indexHeaderShow", 2);
          }else if(this.$route.query && String(this.$route.query.college) === 'true'){
            this.$router.push({ path: "/vocationalExeam" });
            this.$other.$emit("indexHeaderShow", 3);
          }else {
            this.$router.push("/");
            location.reload()
          }
        }else{
          this.$message.error(res.msg)
        }
      })
    },
  //  更新学生学习天数
    getUpdateStudyDay(){
      updateStudyDay().then(res=>{

      })
    },
  //  获取找回密码验证码
    getUpPasswordCode(){
      if (!/^[1]([0-9])[0-9]{9}$/.test(this.Loginform.seekloginPhone)) {
        this.$message.error("手机格式不对");
        return;
      }
      let TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.regtimeshow = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.regtimeshow = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      };
      let data={
        phone:this.Loginform.seekloginPhone
      };
      sendPhoneCode(data).then(res=>{
        if(res.code!==0){
          this.$message.error(res.msg)
        }
      })
    },
  //  找回密码
    findPassword(){
      if(!this.Loginform.seekLogincode){
        this.$message.error("验证码不可为空");
        return;
      };
      if(!this.Loginform.seekLoginPassword){
        this.$message.error("密码不可为空");
        return;
      }
      let data={
        telNumber:this.Loginform.seekloginPhone,
        newPassword:this.Loginform.seekLoginPassword,
        verifyCode:this.Loginform.seekLogincode
      };
      studentUpPassword(data).then(res=>{
        if(res.code==0){
          let data={
            telNumber:this.Loginform.seekloginPhone,
            password:this.Loginform.seekLoginPassword,
            terminalType:'WEB'
          };
          studentPasswordLogin(data).then(res=>{
            if(res.code==0){
              this.$message({
                message: "登录成功",
                type: "success",
              });
              let userInfo = JSON.stringify(res.data)
              localStorage.setItem("userInfo", userInfo);
              this.$cookie.set("userToken", res.data.loginToken);
              this.$store.commit("telNumber", res.data.telNumber);
              this.$store.commit("setUserName", res.data.name);
              this.$router.push("/");
              location. reload()
            }else{
              this.$message.error(res.msg)
            }
          })
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    goPrivacy(){
      window.open('http://exiaozhi.sdzjds.com/privacy/privacy.html')
    },
    goRegAgreement(){
      window.open('http://exiaozhi.sdzjds.com/privacy/safe.html')
    }

  },
  mounted() {
    if(this.$route.query.xzjob){
      this.JobloginAccountShow=true;
      this.teacherShow=false
    }
    if(this.$route.query.forgetPassword){
      this.seekPasswordShow=true;
      this.teacherShow = false;
      this.loginAccountShow = false;
      this.noteLoginShow = false;
    }
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    document.documentElement.scrollTop = 0;
    let closeCodeWindow=sessionStorage.getItem("closeCodeWindow");
    if(closeCodeWindow){
      this.wxLogin.showWxReg=false
    }
    if(this.$route.query.reg){
      this.loginReveal=false;
      this.regShow = true;
    }
  },
};
</script>
<style scoped>
@import "../../public/css/reg.css";
.hidden-input{
  position: relative;
  width: 0;
  height: 0;
  overflow: hidden;
}
/*让input看不见，而不是直接display: none，如果直接display: none，则不生效*/
.hidden-input .form-control{
  position: absolute;
  left: -1000px;
}
.regInputPassword:focus {
  outline:none;
  border: 1px solid #409EFF;
}
.regInputPassword{
  height: 45px;
  padding: 0 15px;
  width: 91%;
  background-color: #FFF;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
}
.regInputColor input::-webkit-input-placeholder {
  color:#b2b5bc;
}

.regInputColor input::-moz-placeholder {
  color:#b2b5bc;
}

.regInputColor input:-ms-input-placeholder {
  color:#b2b5bc;
}
</style>
